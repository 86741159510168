.HeaderBar {
  --header-bg: var(--gray-10);
  --header-color: var(--gray-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HeaderBar.ant-layout-header {
  background: var(--header-bg);
  color: var(--header-color);
  padding: 0 55px 0 24px;
  height: 55px;
  line-height: 53px;
  z-index: 1;
}

.HeaderBar .header-btn {
  cursor: pointer;
  font-size: 18px;
  padding: 0 20px;
  height: 54px;
  display: inline-block;
}

.HeaderBar .header-btn:hover {
  background: rgba(0, 0, 0, 0.07);
}

.HeaderBar .logo-wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

@media (max-width: 767px) {
  .HeaderBar {
    justify-content: normal;
  }

  .HeaderBar.ant-layout-header {
    position: fixed;
    width: 100%;
    z-index: 901;
    background: rgba(0, 0, 0, .98);
    -webkit-backdrop-filter: blur(5px);
  }

  .HeaderBar .header-btn,
  .HeaderBar .header-btn .ant-badge {
    font-size: 14px;
  }

  .HeaderBar .header-btn {
    padding: 0 24px 0 0;
  }

}

