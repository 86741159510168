.BasePage {
  --page-header-title-color: var(--gray-9);
  --page-header-subtitle-color: var(--gray-7);
  --page-header-bg-color: var(--gray-1);
  --page-footer-bg-color: var(--gray-1);

  --base-page-bg-color: var(--gray-3);
  --page-footer-padding: 16px;
  --page-footer-height: 55px;

  position: relative;
  background-color: var(--base-page-bg-color) !important;
}

.BasePage .page-header {
  background-color: var(--page-header-bg-color);
  border-bottom: solid 1px var(--gray-5);
}

.BasePage.with-url-tabs .page-header {
  border-bottom: none;
}

.BasePage .page-header .ant-menu-horizontal {
  border: solid 1px transparent;
}

.BasePage .page-body {
  padding: 16px 24px 16px 24px;
  background-color: var(--base-page-bg-color);
}

.BasePage.with-url-tabs .page-body,
.BasePage .page-body.no-padding {
  padding: 0;
}

.BasePage .ant-layout-footer {
  background-color: var(--page-footer-bg-color);
  border-top: solid 1px #e8e8e8;
  height: var(--page-footer-height);
  padding: 16px 24px;
}

.BasePage .page-footer .ant-btn {
  margin-top: -5px;
}

.BasePage .page-footer .ant-btn + .ant-btn,
.BasePage .inline-footer .ant-btn + .ant-btn,
.BasePage .page-header a + .ant-btn,
.BasePage .page-footer a + .ant-btn,
.BasePage .inline-footer a + .ant-btn,
.BasePage .page-header .ant-btn + a,
.BasePage .page-footer .ant-btn + a,
.BasePage .inline-footer .ant-btn + a,
.BasePage .page-header a + a,
.BasePage .page-footer a + a,
.BasePage .inline-footer a + a {
  margin-left: 8px;
}

.BasePage .inline-footer {
  margin-bottom: 24px;
  overflow: auto;
}

.BasePage .page-header .ant-page-header {
  font-size: inherit;
}

.BasePage .page-header .ant-page-header.has-breadcrumb {
  font-size: inherit;
}

.BasePage .page-header .ant-page-header.has-breadcrumb.has-tabs,
.BasePage.with-url-tabs .page-header .ant-page-header.has-breadcrumb {
  padding: 12px 24px 0 24px;
}

.BasePage .page-header .ant-page-header-heading-left {
  align-items: baseline;
}

.BasePage .page-header .ant-page-header-footer {
  margin-top: 0;
}

.BasePage .page-header .ant-page-header-footer .ant-menu-horizontal:not(.ant-menu-dark) {
  margin-left: -20px;
}

.BasePage .page-header .ant-page-header .ant-breadcrumb + .ant-page-header-heading {
  margin-top: 4px;
}

.BasePage .page-header .ant-page-header-heading-title {
  font-size: var(--font-size-xlarge);
  color: var(--page-header-title-color);
  margin-right: 0;
}

.BasePage .page-header .ant-page-header-heading-title .has-subtitle {
  margin-inline-end: 12px;
}

.BasePage .page-header .ant-page-header-heading-sub-title {
  font-size: var(--font-size-large);
  color: var(--page-header-subtitle-color);
}

.BasePage .page-header .ant-page-header-heading-sub-title .InfoPopoverIcon {
  font-size: var(--font-size-normal);
}

.BasePage .ant-breadcrumb .ant-breadcrumb-link {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  letter-spacing: .075rem;
  font-size: var(--font-size-xxsmall);
}

.BasePage .page-wrapper {
  height: calc(100vh - var(--page-footer-height));
}

.BasePage.main-header-menu-hidden .page-wrapper {
  height: 100vh;
}

@media (min-width: 768px) {
  .BasePage {
    overflow: hidden;
  }

  .BasePage .page-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }

  .BasePage.with-footer .page-wrapper {
    height: calc(100vh - 2 * var(--page-footer-height));
  }

  .BasePage.with-footer.main-header-menu-hidden .page-wrapper {
    height: calc(100vh - var(--page-footer-height));
  }
}

@media (max-width: 767px) {
  .BasePage .page-footer {
    padding: 16px;
  }

  .BasePage .ant-divider-horizontal,
  .BasePage .ant-divider-horizontal.ant-divider-with-text,
  .BasePage .ant-divider-horizontal.ant-divider-with-text-left,
  .BasePage .ant-divider-horizontal.ant-divider-with-text-right {
    margin: 14px 0;
  }

  .BasePage .ant-layout-footer {
    position: fixed;
    z-index: 2;
    bottom: -1px;
    width: 100%;
    padding: var(--page-footer-padding);
  }

  .BasePage:not(.main-header-menu-hidden) .page-wrapper {
    margin-top: var(--page-footer-height);
  }

  .BasePage.with-footer .page-wrapper {
    margin-bottom: var(--page-footer-height);
  }
}
