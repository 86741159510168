.SurveyResponseCard .comments-label {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-small);
}

.SurveyResponseCard p:not(.comments-label) {
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-small);
  color: var(--gray-9);
}

