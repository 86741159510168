/*
This prevents text in the text-area from being covered by the overlay.
We add a bottom white padding of 20px to create a space for the overlay.
This also requires us to remove all existing borders and replace them with an outline to retain the same border style as normal
*/
.TextAreaInput .text-area-with-overlay {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 20px solid #fff !important;
  outline: 1px solid #d9d9d9;
}
