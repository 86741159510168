#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #00ccff;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #00ccff, 0 0 5px #00ccff;
  opacity: 0.5;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 800;
  top: 20px;
  right: 2px;
}

#nprogress .spinner-icon {
  width: 16px;
  height: 16px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #00ccff;
  border-left-color: #00ccff;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 800ms linear infinite;
  animation: nprogress-spinner 800ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  #nprogress .spinner {
    display: none;
  }
}
