.LayoutSpace {
  width: 100%;
}

.LayoutSpace.large > *:not(:last-child) {
  margin-bottom: 24px;
}

.LayoutSpace.medium > *:not(:last-child) {
  margin-bottom: 16px;
}
