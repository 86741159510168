.role-style .solution td .InfoLabel .label,
.role-style .feature td .InfoLabel .label{
  font-size: var(--font-size-small);
  color: var(--gray-9);
  font-weight: var(--font-weight-semi-bold);
}

.role-style .ant-table-thead > tr > th,
.role-style .ant-table-tbody > tr > td {
  overflow-wrap: normal !important;
  width: 150px;
}

.role-style .ant-table-body {
  overflow: hidden;
  overflow-x: scroll;
}

.role-style tr.ant-table-row.solution td {
  background-color: var(--categorical-blue-a-60);
}
.role-style tr.ant-table-row.feature td {
  background-color: var(--categorical-blue-a-35);
}

.role-style tr.ant-table-row.solution.CLIENT_MANAGEMENT_TOOLS > td {
  background-color: var(--categorical-blue-a-60);
}

.role-style tr.ant-table-row.feature.CLIENT_MANAGEMENT_TOOLS td {
  background-color: var(--categorical-blue-a-35);
}

.role-style tr.ant-table-row.solution.INTEL_HUB td{
  background-color: var(--categorical-purple-a-60);
}

.role-style tr.ant-table-row.feature.INTEL_HUB td {
  background-color: var(--categorical-purple-a-35);
}

.role-style tr.ant-table-row.solution.INTERNAL_ADMIN_TOOLS td {
  background-color: var(--categorical-green);
}
.role-style tr.ant-table-row.feature.INTERNAL_ADMIN_TOOLS td {
  background-color: var(--categorical-green-1);
}

.role-style .ant-table-cell{
  padding: 12px 8px;
}

.role-style .ant-table-row-level-2 .label {
  font-size: var(--font-size-small);
  color: var(--gray-9);
  font-weight: var(--font-weight-regular);
}

.role-style .checkbox {
  margin: 0;
  padding: 2;
}

.role-style .ant-table-cell .BaseFormItem {
  margin: 0;
}

.role-style th.STANDARD_CLIENT {
  color: var(--gray-9)
}

.role-style th.STANDARD_INTERNAL {
  color: var(--gray-9)
}

.role-style th.CUSTOM {
  color: var(--gray-9)
}

.role-style .enabled {
  color: var(--severity-informational);
  width: 100%;
}

.role-style .disabled {
  color: var(--gray-6);
  width: 100%;
}
