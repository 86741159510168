/* Responsive size utility styles */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.text-strong {
  font-weight: 500;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.text-xs-left {
  text-align: left !important;
}

.text-xs-right {
  text-align: right !important;
}

.text-xs-center {
  text-align: center !important;
}

.max-width-half {
  max-width: calc(50vw);
}

.max-width-quarter {
  max-width: calc(25vw);
}

.max-width-third {
  max-width: calc(33vw);
}

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

/* Visible Size Ranges */

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 543px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 544px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }

  input, select, textarea {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (max-width: 1599px) {
  .hidden-xl-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}
