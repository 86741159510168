.InfoPopoverIcon{
  --info-icon-color: var(--gray-7);
}

.InfoPopoverIcon.icon {
  margin-inline-start: 4px;
  color: var(--info-icon-color);
  cursor: pointer;
  font-size: 1em;
}

.InfoPopoverIcon.icon:hover,
.InfoPopoverIcon.icon:focus {
  color: var(--primary-blue);
  outline: 0;
}

.InfoPopoverIconOverlay {
  white-space: pre-line !important;
}

.InfoPopoverIconOverlay .ant-popover-inner {
  background: var(--gray-3);
}

.InfoPopoverIconOverlay > .ant-popover-content > .ant-popover-arrow {
  border-color: var(--gray-3);
}

@media (max-width: 768px) {
  .InfoPopoverIconOverlay {
    max-width: 100%;
  }
}
