.profile-meta-data tbody > tr:nth-child(even) > td  {
  padding-bottom: 12px;
}

.profile-meta-data tbody > tr > th  {
  padding-bottom: 4px;
}

.profile-meta-data span.ant-descriptions-item-label  {
  color: var(--gray-9);
  font-weight: var(--font-weight-semi-bold);
}

.profile-meta-data span.ant-descriptions-item-content  {
  color: var(--gray-8);
  font-weight: var(--font-weight-normal);
}