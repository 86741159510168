@value SIZE_XX_SMALL: .625rem; /* 10px */
@value SIZE_X_SMALL: .75rem; /* 12px */
@value SIZE_SMALL: .875rem; /* 14px */
@value SIZE_NORMAL: 1rem; /* 16px */
@value SIZE_LARGE: 1.125rem; /* 18px */
@value SIZE_X_LARGE: 1.25rem; /* 20px */
@value SIZE_XX_LARGE: 1.375rem; /* 22px */
@value SIZE_XXX_LARGE: 1.5rem; /* 24px */

:root {
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight */
  --font-weight-thin: 100;
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-regular: var(--font-weight-normal);
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
  --font-weight-heavy: 900;

  --font-size-xxsmall: .625rem; /* 10px */
  --font-size-xsmall: .75rem; /* 12px */
  --font-size-small: .875rem; /* 14px */
  --font-size-normal: 1rem; /* 16px */
  --font-size-large: 1.125rem; /* 18px */
  --font-size-xlarge: 1.25rem; /* 20px */
  --font-size-xxlarge: 1.375rem; /* 22px */
  --font-size-xxxlarge: 1.5rem; /* 24px */

  --font-size-header-normal: 1.5rem; /* 24px */
  --font-size-header-xlarge: 2.625rem; /* 42px */

  --line-height-xlarge: 1.25rem; /* 20px */
  --line-height-xxlarge: 1.375rem; /* 22px */
}
