.Loading {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 55px);
  pointer-events: none;
}

.Loading .ant-spin {
  position: absolute;
  left: 50%;
  top: 50%;
}
