@value ratingExtreme: #D43D3D;
@value ratingHigh: #E98E3F;
@value ratingModerate: #F7C143;
@value ratingLow: #A4B952;
@value ratingMinimal: #6AB48F;
@value ratingUnrated: #D9D9D9;
@value transparent: transparent;

@value borderBlue: rgba(62, 144, 204, 0.6);
@value borderPurple: rgba(120, 53, 172, 0.6);
@value borderMaroon: rgba(175, 49, 98, 0.6);
@value borderRed: rgba(213, 43, 30, 0.6);
@value borderSpanishOrange: rgba(229, 101, 15, 0.6);
@value borderWebOrange: rgba(245, 158, 0, 0.6);
@value borderApricot: rgba(242, 186, 127, 0.6);

@value gray10: #000000;
@value gray9: #262626;
@value gray8: #595959;
@value gray7: #8C8C8C;
@value gray6: #BFBFBF;
@value gray5: #D9D9D9;
@value gray4: #F0F0F0;
@value gray3: #F5F5F5;
@value gray2: #FAFAFA;
@value gray1: #FFFFFF;

@value primaryBlue: #096DD9;
@value daybreakBlue1: #E6F7FF;
@value daybreakBlue3: #91D5FF;
@value daybreakBlue6: #1890FF;
@value categoricalBlue: #3E90CC;
@value categoricalPurple: #7835AC;
@value categoricalBurgundy: #AF3162;
@value categoricalRed: #D52B1E;
@value categoricalRed6: #F5222D;
@value categoricalRed8: #A8071A;
@value categoricalOrange: #E5650F;
@value categoricalYellow: #F59E00;
@value categoricalTan: #F2BA7F;
@value categoricalPink: #F1C3A9;
@value categoricalGreen: #389E0D;
@value categoricalGreen1: #77D540;
@value categoricalGreen6: #52C41A;
@value categoricalGreen8: #237804;
@value categoricalGold9: #874D00;

:root {
  --gray-10: gray10;
  --gray-9: gray9;
  --gray-8: gray8;
  --gray-7: gray7;
  --gray-6: gray6;
  --gray-5: gray5;
  --gray-4: gray4;
  --gray-3: gray3;
  --gray-2: gray2;
  --gray-1: gray1;

  /* 60% alpha 99*/
  --categorical-purple-a-60: #7835AC99;
  --categorical-blue-a-60: #3E90CC99;
  --categorical-gray-a-60: #D9D9D999;
  --categorical-yellow-a-60: #F59E0099;

  /* 35% alpha 59*/
  --categorical-purple-a-35: #7835AC59;
  --categorical-blue-a-35: #3E90CC59;
  --categorical-gray-a-35: #D9D9D959;

  /* 65% alpha A6 */
  --gray1-a-65: #FFFFFFA6;
  /* 10% alpha 1A */
  --gray10-a-10: #0000001A;

  --magenta-7: #C41D7F;

  --primary-blue: #096DD9;
  --daybreak-blue-1: #E6F7FF;
  --daybreak-blue-3: #91D5FF;
  --daybreak-blue-6: #1890FF;
  --categorical-blue: #3E90CC;
  --categorical-blue-1: #3E90CC29;
  --categorical-purple: #7835AC;
  --categorical-burgundy: #AF3162;
  --categorical-red: #D52B1E;
  --categorical-red-6: #F5222D;
  --categorical-red-7: #CF1322;
  --categorical-red-8: #A8071A;
  --categorical-orange: #E5650F;
  --categorical-yellow: #F59E00;
  --categorical-tan: #F2BA7F;
  --categorical-pink: #F1C3A9;
  --categorical-green: #389E0D;
  --categorical-green-1: #77D540;
  --categorical-green-6: #52C41A;
  --categorical-green-8: #237804;
  --categorical-gold-9: #874D00;

  --rating-extreme: ratingExtreme;
  --rating-high: ratingHigh;
  --rating-moderate: ratingModerate;
  --rating-low: ratingLow;
  --rating-minimal: ratingMinimal;
  --rating-unrated: ratingUnrated;

  --severity-critical: #FF2D55;
  --severity-warning: #FA7800;
  --severity-caution: #FFCC00;
  --severity-informational: #34C759;
  --severity-potential: #000000;

  --antd-danger: #ff4d4f;

  --borderBlue: borderBlue;
  --borderPurple: borderPurple;
  --borderMaroon: borderMaroon;
  --borderRed: borderRed;
  --borderSpanishOrange: borderSpanishOrange;
  --borderWebOrange: borderWebOrange;
  --borderApricot: borderApricot;
}
