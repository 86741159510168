.InfoLabel .label {
  font-weight: initial;
}

.InfoLabel .label.bold {
  font-weight: 500;
}

.InfoLabel .secondary {
  float: inline-end;
  max-height: 1.5em;
}

.tabHeading .InfoLabel {
  font-size: 1.2em;
  display: block;
  margin: 12px 0;
}

.InfoLabel .disabled .ant-typography {
  color: var(--gray-5);
}
