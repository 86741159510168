.HeaderLogo img {
  display: inline-block;
  max-height: 20px;
  max-width: 200px;
  width: auto;
  height: auto;
}

.HeaderLogo .logo-divider {
  margin: 0;
  border-left: 1px solid var(--gray-7);
  height: 26px;
}

@media (min-width: 1200px) {
  /*.HeaderLogo img {*/
  /*  height: 18px;*/
  /*}*/

  .HeaderLogo img.client-logo-top-bar {
    height: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  /*.HeaderLogo img {*/
  /*  height: 18px;*/
  /*}*/

  .HeaderLogo img.client-logo-top-bar {
    height: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .HeaderLogo img {
    height: 20px;
  }

  .HeaderLogo img.client-logo-top-bar {
    height: 20px;
  }
}

@media (min-width: 768px) {
  .header-right {
    padding-inline-end: 6px;
  }

  .header-left {
    display: none;
  }
}

@media (max-width: 767px) {

  .HeaderLogo {
    align-self: flex-start;
    max-width: 20px;
  }

}