.CourseCompletion {
  width: 100%;
  height: 100vh;
  background: var(--gray-3);
}

.CourseCompletion .wrapper {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.CourseCompletion .wrapper .content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

