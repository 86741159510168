.SurveyResponseFormCard .ant-card-head {
  background-color: var(--gray-2);
}

.SurveyResponseFormCard .radio-group-vertical .ant-radio-wrapper {
  display: block;
  padding-bottom: 10px;
}

.SurveyResponseFormCard .ant-card-head-title {
  white-space: pre-wrap;
}

.SurveyResponseFormCard .ant-card-body {
  padding: 12px 24px;
}

.SurveyResponseFormCard .BaseFormItem {
  margin-bottom: 12px;
}